import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Transaction from '../views/Transaction.vue';
import FourOFour from '../views/404.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Generate receipt for Bitcoin Cash transactions',
    },
  },
  {
    path: '/tx/:txid/:fiat',
    name: 'Transaction Receipt',
    component: Transaction,
    meta: {
      title: 'Bitcoin Cash Transaction Recept',
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: FourOFour,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
