<template>
  <div>
    That page does not exist. <a href="/">Go home</a>.
  </div>
</template>

<script>

export default {
  name: 'FourOFour',
  components: {
  },
};
</script>
