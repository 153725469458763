<template>
  <div class="home" :key="$route.params.txid + $route.params.fiat">
    <Receipt
        :txid="$route.params.txid"
        :fiat="$route.params.fiat"/>
  </div>
</template>

<script>
import Receipt from '@/components/Receipt.vue';

export default {
  name: 'Transaction',
  components: {
    Receipt,
  },
};
</script>
