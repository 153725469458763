/* eslint-disable-next-line */
const CoinGecko = require('coingecko-api');

const COINGECKO_COIN_ID = 'bitcoin-cash';

/// Find nearest (in time) historical price to given timestamp.
function findClosestPrice(timestamp: number, prices: Array<Array<number>>) {
  const mid = Math.floor(prices.length / 2);
  return prices[mid];
}

export async function fetchExchangeRate(fiat: string, time: number | null):
    Promise<Record<string, number | string>> {
  const useHistorical = time !== null;

  const gecko = new CoinGecko();

  if (!useHistorical) {
    // Timestamp not provided, Fetch current price.
    const response = await gecko.simple.price({
      ids: COINGECKO_COIN_ID,
      vs_currencies: fiat,
    });
    if (!response.success) {
      throw Error(`Failed to get fiat rates ${response}`);
    }

    const rate = response.data[COINGECKO_COIN_ID][fiat];
    return {
      fiat,
      rate,
      time: Math.ceil(Date.now()),
      source: 'current rate from CoinGecko (coingecko.com)',
    };
  }

  /* How many seconds +- to fetch rates for. Set this too low, and we'll get
   * no rates at all. Set it too high and we'll get too much data, or not
   * granular enough */
  const plusminus = 3600 * 2;

  const response = await gecko.coins.fetchMarketChartRange(COINGECKO_COIN_ID, {
    vs_currency: fiat,
    from: time as number - plusminus,
    to: time as number + plusminus,
  });
  if (!response.success) {
    throw Error(`Failed to get fiat rates ${response}`);
  }
  // eslint-disable-next-line
  const prices = response.data.prices;
  if (prices === undefined) {
    throw Error(`Invalid historical BCH/${fiat} rate response: ${response}`);
  }
  if (!prices.length) {
    throw Error(`Found no historical BCH / ${fiat} prices at timestamp ${time} `);
  }

  const rate = findClosestPrice(time as number, prices);

  return {
    fiat,
    time: rate[0],
    rate: rate[1],
    source: 'historical rates from CoinGecko (coingecko.com)',
  };
}

export async function getVsCurrencies(): Promise<Array<string>> {
  const gecko = new CoinGecko();
  const response = await gecko.simple.supportedVsCurrencies();
  if (!response.success) {
    throw Error(`Failed to get versus currencies ${response}`);
  }
  return response.data;
}
