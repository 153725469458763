<template>
  <div class="home" style="text-align: center;">
    <h1 style="text-align: center; padding-top: 1em;">Generate a transaction receipt</h1>
    <h5>
        Generate a receipt for <strong>any</strong> transaction on the Bitcoin Cash blockchain.
    </h5>

    <div>
        <SearchBar />
    </div>

    <b-container style="margin-top: 1em;">
        <b-row>
            <b-col>Use rates from <em>the moment the transaction was confirmed</em>.</b-col>
            <b-col>Select your output. The receipt is <em>explicit about
              your transacted amount</em>. Ignore change outputs.</b-col>
            <b-col>Powered by the Electrum infrastructure. Enjoy more privacy as your browser loads
              the blockchain data, not us.
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'Home',
  components: {
    SearchBar,
  },
};
</script>
