
















import { Component, Vue, Prop } from 'vue-property-decorator';

function hex2ascii(hex: string) {
  let str = '';
  for (let i = 0; (i < hex.length && hex.substr(i, 2) !== '00'); i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

Vue.filter('opreturn', (asm: string) => {
  const code = asm.split(' ');
  const data = code[code.length - 1];
  const ascii = hex2ascii(data);
  if (ascii.length > 0) {
    return `${data} [converted to text: ${ascii}]`;
  }
  return data;
});

@Component({
  components: {
  },
})

export default class OutputAddress extends Vue {
  // eslint-disable-next-line
  @Prop() private scriptPubKey!: any;
}

