


































































































import {
  Prop, Component, Vue,
} from 'vue-property-decorator';
import OutputAddress from '@/components/OutputAddress.vue';
import { getTransaction } from '../electrum';
import { fetchExchangeRate } from '../rates';

@Component({
  components: {
    OutputAddress,
  },
})
export default class Receipt extends Vue {
    @Prop() private txid!: string;

    @Prop() private fiat!: string;

    private tx: any = null;

    private inputTxs: Map<string, any> = new Map();

    private minerFee = -1.0;

    private exchangeRate: Record<string, string | number> | null = null;

    private txdataReady = false;

    private fiatdataReady = false;

    private error: string | null = null;

    private selected = -1;

    async created(): Promise<void> {
      try {
        const tx = await getTransaction(this.txid);
        if (tx instanceof Error) {
          throw tx;
        }
        this.tx = tx;
        const rateFuture = fetchExchangeRate(this.fiat, this.tx.time);
        console.log(this.tx);
        await this.fetchInputTxs(this.tx);
        this.calculateMinerFee(this.tx);
        this.autoSelectOutput(this.tx);

        this.txdataReady = true;
        this.exchangeRate = await rateFuture;
        this.fiatdataReady = true;
      } catch (e) {
        console.error(e);
        this.error = e.toString();
      }
    }

    // eslint-disable-next-line
    async fetchInputTxs(tx: any): Promise<void> {
      const futures: Promise<any>[] = [];
      tx.vin.forEach((i: any) => {
        futures.push(getTransaction(i.txid));
      });
      const txs = await Promise.all(futures);
      txs.forEach((t: any) => {
        if (t instanceof Error) {
          throw t;
        }
        this.inputTxs.set(t.txid, t);
      }, this);
    }

    selectOutput(i: number): void {
      console.log(`selected output ${i}`);
      this.selected = i;
    }

    getFiatValue(coin: number): string {
      if (this.exchangeRate === null) {
        return '';
      }
      const fiatToken = (this.exchangeRate.fiat as string).toUpperCase();
      let fiatValue = (this.exchangeRate.rate as number) * coin;
      // Round to two digits
      fiatValue = Math.round(fiatValue * 100) / 100;
      return `(${fiatValue} ${fiatToken})`;
    }

    autoSelectOutput(tx: any): void {
      // Just default to first output with any amount in it.
      for (let i = 0; i < tx.vout.length; i += 1) {
        if (tx.vout[i].value_satoshi === 0) {
          /* eslint-disable-next-line */
          continue;
        }
        this.selected = i;
        return;
      }
    }

    calculateMinerFee(tx: any): void {
      let coinsIn = 0;
      let coinsOut = 0;

      try {
        tx.vin.forEach((i: any) => {
          if (i.coinbase) {
            return;
          }
          coinsIn += this.inputTxs.get(i.txid).vout[i.vout].value_satoshi;
        });
        tx.vout.forEach((o: any) => {
          coinsOut += o.value_satoshi;
        });
      } catch (e) {
        console.error(e);
        this.error = `Failed to calculate miner fee: ${e.toString()}`;
      }
      if (coinsIn < coinsOut) {
        // coinbase transaction
        this.minerFee = -1;
      } else {
        this.minerFee = (coinsIn - coinsOut) / 100000000;
      }
    }
}
