






































import { Component, Vue } from 'vue-property-decorator';
import { getVsCurrencies } from '../rates';

@Component({
  components: {
  },
})
export default class SearchBar extends Vue {
  private query = '';

  private vsCurrencies: Array<string> = [];

  private vsCurrency = 'USD';

  private error: string | null = null;

  onSubmit(): void {
    try {
      localStorage.setItem('vsCurrency', this.vsCurrency);
    } catch (e) {
      console.log(`Unable to store vsCurrency in browser: ${e}`);
    }
    const fiat = this.vsCurrency.toLowerCase();
    try {
      this.$router.push({ path: `/tx/${this.query.trim()}/${fiat}` });
    } catch (e) {
      this.error = e.toString();
      console.error(e);
    }
  }

  async created(): Promise<void> {
    try {
      const savedVs = localStorage.getItem('vsCurrency');
      if (savedVs !== null) {
        this.vsCurrency = savedVs;
      }
    } catch (e) {
      console.log(`Unable to load vsCurrencies from browser: ${e}`);
    }
    try {
      this.vsCurrencies = await getVsCurrencies();
      this.vsCurrencies.sort();
    } catch (e) {
      this.error = `Unable to load versus-currencies: ${e}`;
      this.vsCurrency = 'USD';
      this.vsCurrencies = ['USD'];
      console.error(e);
    }
    this.vsCurrencies = this.vsCurrencies.map((c) => c.toUpperCase());
  }

  filterCurrency(input: string): Array<string> {
    if (input.length < 1) {
      return [];
    }
    return this.vsCurrencies.filter((c) => c.toLowerCase().startsWith(input.toLowerCase()));
  }
}

